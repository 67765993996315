import { Card, Title, Button, Text } from "@tremor/react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getCookie, setCookie } from "cookies-next";
import getLanguage from "@/app/utils/getlanguage";
import translation from "./locale/index.json";
import Link from "next/link";

const CookieKey = "p4u-cookies";

export default function CookiesWidget() {
  const { locale } = useRouter();
  const content = getLanguage(translation, locale);
  const [cook, setCook] = useState(false);

  useEffect(() => {
    const cookies = getCookie(CookieKey) || null;
    if (cookies) setCook(true);
  }, []);

  const setCookies = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1)
    setCookie(CookieKey, true, { expires: expirationDate });
    setCook(true);
  };

  return (
    <>
      {!cook ? (
        <div className="fixed bottom-0 right-0 p-4 min-w-[5%] md:max-w-[30%]">
          <Card>
            <Title className="mb-5">{content.title}</Title>
            <Text>{content.text}</Text>
            <Link href={'/privacy-policy'}>{content.moreText}</Link>            
            <Button onClick={setCookies} className="min-w-full mt-2">
              {content.btn}
            </Button>
          </Card>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
